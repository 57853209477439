


export let enviromentnode = process.env.REACT_APP_DEV === "active"

export const endPointProcesso = enviromentnode ? process.env.REACT_APP_END_POINT_PROCESSO_DEV : process.env.REACT_APP_END_POINT_PROCESSO_PROD
export const endPointManutencao = enviromentnode ? process.env.REACT_APP_END_POINT_MANUTENCAO_DEV : process.env.REACT_APP_END_POINT_MANUTENCAO_PROD
export const endPointCommons = enviromentnode ? process.env.REACT_APP_END_POINT_COMMONS_DEV : process.env.REACT_APP_END_POINT_COMMONS_PROD
export const endPointSuprimentos = enviromentnode ? process.env.REACT_APP_END_POINT_SUPRIMENTOS_DEV : process.env.REACT_APP_END_POINT_SUPRIMENTOS_PROD
export const endPointTimer = /* enviromentnode ? process.env.REACT_APP_END_POINT_TIMER : */ process.env.REACT_APP_END_POINT_TIMER_PROD
export const endPointUser = /* enviromentnode ? process.env.REACT_APP_END_POINT_USER : */ process.env.REACT_APP_END_POINT_USER_PROD
export const endPointRabbit = /* enviromentnode ? process.env.REACT_APP_END_POINT_RABBIT : */ process.env.REACT_APP_END_POINT_RABBIT_PROD
export const endPointMathema = /* enviromentnode ? process.env.REACT_APP_END_POINT_MATHEMA : */ process.env.REACT_APP_END_POINT_MATHEMA_PROD
export const endPointCorrecao = /* enviromentnode ? process.env.REACT_APP_END_POINT_CORRECAO : */ process.env.REACT_APP_END_POINT_CORRECAO_PROD
export const endPointWorkPlan = /* enviromentnode ? process.env.REACT_APP_END_POINT_CORRECAO : */ process.env.REACT_APP_END_POINT_WORKPLAN_PROD
export const endPointPlanoDeControle = enviromentnode ? process.env.REACT_APP_END_POINT_PLANODECONTROLE_DEV : process.env.REACT_APP_END_POINT_PLANODECONTROLE_PROD


export const ids = {
	novoRecursoButton: 'novoRecursoButton',
	novaSolicitacaoButton: "novaSolicitacaoButton",
	novaOrdemButton: "novaOrdemButton",
	checkActiveRecurso: "checkActiveRecurso",
	editRecursoButton: "editRecursoButton",
	solicitacoesDeServicoAcaoColumn: 'solicitacoesDeServicoAcaoColumn',
	ordensDeServicoIndicadorButton: 'ordensDeServicoIndicadorButton',
	ordensDeServicoTarefasColumn: 'ordensDeServicoTarefasColumn',
	ordensDeServicoAcaoColumn: 'ordensDeServicoAcaoColumn',
	ordensDeServicoTempoColumn: 'ordensDeServicoTempoColumn',
}

export const beforeSaveVerifyHandler = (form, addToastCallback) => {

	if (form.recursos.length === 0) {
		addToastCallback("Selecione pelo menos um recurso", {
			appearance: "warning",
			autoDismiss: true,
		})
	} else if (form.descricaoDoProblema.length === 0) {
		addToastCallback("Preencha a descrição do problema", {
			appearance: "warning",
			autoDismiss: true,
		})
	} else return true
}

//export const endPointProcesso =  "http://localhost:4010/"
//export const endPointManutencao = "http://localhost:4001/"
//export const endPointSuprimentos = "http://localhost:4014/"
//export const endPointCommons = "http://localhost:4012/"
//export const endPointTimer = "http://localhost:4030/"
//export const endPointUser = "http://localhost:4008/"
// export const endPointRabbit = "http://localhost:3005/"
//  export const endPointCorrecao = "http://localhost:3009/"
// export const endPointMathema = "http://localhost:8001/"
//export const endPointWorkPlan = "http://localhost:4019/"
//export const endPointPlanoDeControle = "http://localhost:3044/"

export const paths = {
   acessoNaoPermitido: "/acessoNaoPermitido",
   agregadorForm: "/agregadorForm",
   alarmeForm: "/alarmeForm",
   operacaoForm: "/operacaoForm",
   approvaStatus: "/aprovacaoStatus",
   arvoreEstruturalForm: "/arvoreEstruturalForm",
   branchLevelForm: "/branchLevelForm",
   cadernoDeVerificacao: "/cadernoDeVerificacao",
   cadernoDeVerificacaoForm: "/cadernoDeVerificacaoForm",
   campoDeVerificacao: "/campoDeVerificacao",
   classeDeComponenteForm: "/classeDeComponenteForm",
   cadernos: "/cadernos",
   formularioForm: "/formularioForm",
   planosDeControle: "/planosDeControle",
   classeDeRecursoForm: "/classeDeRecursoForm",
   classeDeRecursoPicker: "/classeDeRecursoPicker",
   composicaoForm: "/composicaoForm",
   componenteForm: "/componenteForm",
   controleForm: "/controleForm",
   cronogramaManutencao: "/cronogramaManutencao",
   cadernos: "/cadernos",
   dimensaoForm: "/dimensaoForm",
   estoqueForm: "/estoqueForm",
   estoques: "/estoques",
   especificacaoForm: "/especificacaoForm",
   primeiroLoginForm: "/FirstLogin",
   frequenciaForm: "/frequenciaForm",
   fluxoDeProcessoForm: "/fluxoDeProcessoForm",
   itemDeRequisicao: "/itemDeRequisicao",
   kardex: "/kardex",
   linhaDePlanoDeControleForm: "/linhaDePlanoDeControleForm",
   limiteDeControleForm: "/limiteDeControleForm",
   loginForm: "/Login",
   mantenedores: "/mantenedores",
   materialCompostoForm: "/materialCompostoForm",
   materialForm: "/materialForm",
   parametroForm: "/parametroForm",
   planoDeControleDashboard: "/planoDeControleDashboard",
   planoDeControleForm: "/planoDeControleForm",
   planoDeManutencaoForm: "/planoDeManutencaoForm",
   planosDeManutencao: "/planosDeManutencao",
   processoForm: "/processoForm",
   recorrenciaForm: "/recorrenciaForm",
   registroDeCampoChart: "/registroDeCampoChart",
   registroForm: "/registroForm",
   registroDeTarefaForm: "/registroDeTarefaForm",
   requisicaoMaterialForm: "/requisicaoMaterialForm",
   requisicoesDeMaterial: "/requisicoesDeMaterial",
   recursoDisplayer: "/recursoDisplayer",
   recursoPicker: "/recursoPicker",
   recursoForm: "/recursoForm",
   regraDeAgregacaoForm: "/regraDeAgregacaoForm",
   regraDeCorrecaoForm: "/regraDeCorrecaoForm",
   setorForm: "/setorForm",
   solicitacaoDeServicoForm: "/solicitacaoDeServicoForm",
   solicitacoesDeServico: "/solicitacoesDeServico",
   tarefaForm: "/tarefaForm",
   templateDeDados: "/templateDeDados",
   testing: "/testingAreaMenu",
   timeForm: "/timeForm",
   times: "/times",
   turnoForm: "/turnoForm",
   unidadeForm: "/unidadeForm",
   unidadeMaterialForm: "/unidadeMaterialForm",
   workPlanForm: "/workPlanForm",
   ordemDeManutencaoForm: "/ordemDeManutencaoForm",
   ordensDeManutencao: "/ordensDeManutencao",
   ordemDeServicoForm: "/ordemDeServicoForm",
   ordensDeServico: "/ordensDeServico",
   folhaDeVerificacaoForm: "/folhaDeVerificacaoForm",
   modoDeFalhaForm: "/modoDeFalhaForm",
   cadernoDeVerificacaoForm: "/cadernoDeVerificacaoForm",
   cronogramaManutencao: "/cronogramaManutencao",
   workForm: "/workForm",
   workPlans: "/workPlans",
   workPlan: "/workPlan",
   workFlow: "/workFlow",
   workFlows: "/workFlows"
};


export const tipoFrequencia = ["DATE", "COUNTER"];
export const estadoStatus = ['PENDENTE', 'APROVADO', 'REPROVADO']
export const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
export const escala = ["DIA", "HORAS", "MINUTOS", "SEGUNDOS", "MILISEGUNDOS"]
export const tiposDeCampo = [{ nome: 'Textual', type: "text" }, { nome: 'Numérico', type: "number" }, { nome: 'Tempo', type: "time" }, { nome: 'ƒ(x)', type: "formula" }]
export const statusColor = (estado) => {


	if (estado === 'PENDENTE') return { color: 'orange', fontWeight: 'bold' };
	if (estado === 'REPROVADO') return { color: 'red', fontWeight: 'bold' };
	if (estado === 'APROVADO') return { color: 'green', fontWeight: 'bold' };
};
export const colors = {
	vermelhoPastel: { H: '#FAA0A0', R: "rgba(250, 160, 160, 0.5)" },
	verdePastel: { H: '#A4FBA6', R: "rgba(164,251,166, 0.5)" },
	cinzaClaro: { H: '#d1d0cd', R: "rgba(143, 142, 140, 0.3)" },
	amareloClaro: { H: '#f5d164', R: "rgba(143, 142, 140, 0.3)" },
	azulClaro: { H: '#6dd1ed', R: "rgba(143, 142, 140, 0.3)" },
	amareloEscuro: { H: '#eecb06', R: "rgba(238, 203, 6, 0.5)" }
}

/* export const labels = (language) => {
   return {Valor: {}}
} */

export const version = "1.1.0";

