import { createSlice } from '@reduxjs/toolkit'



const getLPCIndexByPos = (lpcs, pos) => {
	return lpcs.findIndex(lpc => lpc.posicao == pos);
}

const initialState = {
	form: {
		nome: '',
		processo: '',
		fluxoDeProcesso: '',
		linhasDePlanoDeControle: [],
		linhasDePlanoDeControleToRemove: [],
		controlesToRemove: []
	},
}

const planoDeControleReducer = createSlice({
	name: 'planoDeControleReducer',
	initialState,
	reducers: {
		setProcesso(state, action) {
			state.form.processo = action.payload
		},
		setFluxo(state, action) {
			state.form.fluxoDeProcesso = action.payload
		},
		setLinhaDePlanoDeControle(state, action) {
			if (state.form.linhasDePlanoDeControle) {
				if (Array.isArray(action.payload)) state.form.linhasDePlanoDeControle = [...state.form.linhasDePlanoDeControle, ...action.payload]
				else state.form.linhasDePlanoDeControle = [...state.form.linhasDePlanoDeControle, action.payload]
			} else {
				if (Array.isArray(action.payload)) state.form.linhasDePlanoDeControle = action.payload
				else state.form.linhasDePlanoDeControle = new Array(action.payload)
			}
		},
		overWriteLpcs(state,action) {
			state.form.linhasDePlanoDeControle = action.payload
		},

		setEspecificacaoControle(state, action) {
			let { linhaIndex, controleIndex } = action.payload

			state.form.linhasDePlanoDeControle[linhaIndex].controles[controleIndex].especificacao =
				action.payload
		},
		setAcionadoresControleDeProcesso(state, action) {
			state.controleDeProcesso.acionadores = action.payload
		},
		setPosicaoLinhaDePlanoDeControle(state, action) {
			let { index, newPosicao } = action.payload
			state.form.linhasDePlanoDeControle[index].posicao = newPosicao
		},
		setUpdateSingleLPC(state, action) {
			let index
			if (action.payload.index) index = action.payload.index
			else index = state.form.linhasDePlanoDeControle.findIndex(lpc => lpc.id == action.payload.id)
			state.form.linhasDePlanoDeControle[index] = action.payload
		},

		setUpdateSingleControle(state, action) {
			let { indexControle, posLPC } = action.payload
			let indexToUpdate = getLPCIndexByPos(state.form.linhasDePlanoDeControle, posLPC)
			state.form.linhasDePlanoDeControle[indexToUpdate].controles[indexControle] = action.payload
		},
		removeControleFromLPC(state, action) {
			let { indexControle, posLPC } = action.payload
			let indexToUpdate = getLPCIndexByPos(state.form.linhasDePlanoDeControle, posLPC)
			state.form.linhasDePlanoDeControle[indexToUpdate].controles = state.form.linhasDePlanoDeControle[indexToUpdate].controles.filter((c, index) => {

				if (index != indexControle) {
					return true
				}
				else {
					state.form.controlesToRemove.push(c)

					return false
				}
			})
		},
		copiarControle(state, action) {
			state.form.controleCopy = action.payload
		},

		apendSingleControle(state, action) {
			let { posLPC } = action.payload
			let indexToUpdate = getLPCIndexByPos(state.form.linhasDePlanoDeControle, posLPC)
			if (!state.form.linhasDePlanoDeControle[indexToUpdate].controles) state.form.linhasDePlanoDeControle[indexToUpdate].controles = []
			state.form.linhasDePlanoDeControle[indexToUpdate].controles = [...state.form.linhasDePlanoDeControle[indexToUpdate].controles, action.payload]
		},

		apendRecurso(state, action) {
			let { posLPC } = action.payload
			let indexToUpdate = getLPCIndexByPos(state.form.linhasDePlanoDeControle, posLPC)
			state.form.linhasDePlanoDeControle[indexToUpdate].recurso = action.payload
			state.form.linhasDePlanoDeControle[indexToUpdate].recursoId = `${action.payload.id}`
		},
		setUpdateOperacaoLPC(state, action) {
			let { posicao, operacao } = action.payload
			let indexToUpdate = getLPCIndexByPos(state.form.linhasDePlanoDeControle, posicao)
			state.form.linhasDePlanoDeControle[indexToUpdate].operacao = operacao
			state.form.linhasDePlanoDeControle[indexToUpdate].operacaoId = `${operacao.id}`
		},
		setUpdateEspecificacaoControle(state, action) {
			let { indexControle, posLPC } = action.payload
			let indexToUpdate = getLPCIndexByPos(state.form.linhasDePlanoDeControle, posLPC)
			state.form.linhasDePlanoDeControle[indexToUpdate].controles[indexControle].especificacao = action.payload
		},
		setUpdateFrequenciaControle(state, action) {
			let { indexControle, posLPC } = action.payload
			let indexToUpdate = getLPCIndexByPos(state.form.linhasDePlanoDeControle, posLPC)
			state.form.linhasDePlanoDeControle[indexToUpdate].controles[indexControle].frequencia = action.payload
		},
		setNome(state, action) {
			state.form.nome = action.payload
		},
		setLinhasDePlanoDeControle(state, action) {
			state.form.linhasDePlanoDeControle = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
			state.form.linhasDePlanoDeControleToRemove = []
			state.form.controlesToRemove = []
		},
		clear(state, action) {
			state.form = initialState.form
		},
		removeLinhaPlanoDeControle(state, action) {
			state.form.linhasDePlanoDeControle = state.form.linhasDePlanoDeControle.filter(
				(lpc) => {
					if (lpc.posicao != action.payload) {
						return true
					}
					else {
						if (state.form.linhasDePlanoDeControleToRemove) state.form.linhasDePlanoDeControleToRemove.push(lpc)
						else state.form.linhasDePlanoDeControleToRemove = [lpc]
						if (lpc.controles.length) lpc.controles.forEach(c => state.form.controlesToRemove.push(c))
						return false
					}
				}
			)
		},
	},
})



export const {
	setProcesso,
	setFluxo,
	overWriteLpcs,
	setOpercaoLinhaPlanoDeControle,
	setLinhaDePlanoDeControle,
	apendSingleControle,
	apendRecurso,
	setNome,
	copiarControle,
	clear: clearPlanoDeControleForm,
	setEspecificacaoControle,
	setAcionadoresControleDeProcesso,
	removeLinhaPlanoDeControle,
	setPosicaoLinhaDePlanoDeControle,
	setUpdateFrequenciaControle,
	populateToEdit: populateToEditPlanoDeControleReducer,
	setUpdateSingleLPC,
	removeControleFromLPC,
	setUpdateSingleControle,
	setUpdateEspecificacaoControle,
	setUpdateOperacaoLPC,
	setLinhasDePlanoDeControle
} = planoDeControleReducer.actions
export default planoDeControleReducer.reducer
