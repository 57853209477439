
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { withMenuBar } from './Hocs/withMenuBar';
import { isAuthenticated } from './Services/auth';
import { routesConfig } from './models/routesConfig';


const servicosContratados = ['processo', 'manutencao', 'suprimentos', 'commons', 'user', 'workplan'];


const Router = () => {

	const global = useSelector(state => state.global)

  return (
    <Routes>
      {isAuthenticated(global.token)
        ? routesConfig.filter((rC) => servicosContratados.includes(rC.service)).map((rC) =>
            rC.routes.flatMap((route) => (
              <Route
                key={route.configuration.path}
                path={route.configuration.path}
                element={
                  <Suspense fallback={<div>Carregando...</div>}>
                    <route.element {...route.configuration} />
                  </Suspense>
                }
              />
            ))
          )
        : routesConfig
            .filter((r) => r.service === "user")
            .filter((rC) => servicosContratados.includes(rC.service))
            .map((rC) =>
              rC.routes.flatMap((route) => (
                <Route
                  key={route.configuration.path}
                  path={route.configuration.path}
                  element={
                    <Suspense fallback={<div>Carregando...</div>}>
                      <route.element {...route.configuration} />
                    </Suspense>
                  }
                />
              ))
            )}
    </Routes>
  );
};

export default withMenuBar(Router);