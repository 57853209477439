import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	form: {
		planejado: 0,
		realizado: 0,
		regrasDeAgregacao: [],
	},
}

const agregadorReducer = createSlice({
	name: "agregadorReducer",
	initialState,
	reducers: {
		setPlanejado(state, action) {
			state.form.planejado = action.payload
		},
		setRealizado(state, action) {
			state.form.realizado = action.payload
		},
		setRegrasDeAgregacao(state, action) {
			state.form.regrasDeAgregacao = action.payload
		},
		clear(state) {
			state.form = initialState.form
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
	},
})

export const {
	setPlanejado,
	setRealizado,
	setRegrasDeAgregacao,
	clear: clearAgregador,
	populateToEdit: populateAgregador,
} = agregadorReducer.actions

export default agregadorReducer.reducer
