import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	form: {
		agregadorId: null,
		contadorId: "",
		formula: "[V0]",
	},
}

const regraDeAgregacaoReducer = createSlice({
	name: "regraDeAgregacaoReducer",
	initialState,
	reducers: {
		setAgregadorId(state, action) {
			if (action.payload.id) state.form.agregadorId = action.payload.id
			state.form.agregador = action.payload
		},
		setContadorId(state, action) {
			if (action.payload.id) state.form.contadorId = action.payload.id
			state.form.contador = action.payload
		},
		setFormulaRegraDeAgregacao(state, action) {
			state.form.formula = action.payload
		},
		clear(state) {
			state.form = initialState.form
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
	},
})

export const {
	setAgregadorId,
	setContadorId,
	setFormulaRegraDeAgregacao,
	clear: clearRegraDeAgregacao,
	populateToEdit: populateRegraDeAgregacao,
} = regraDeAgregacaoReducer.actions

export default regraDeAgregacaoReducer.reducer
