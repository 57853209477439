import React, { createContext, useContext, useRef } from "react"

// Contexto para armazenar as referências de validação
const ValidationContext = createContext()

export const ValidateProvider = ({ children }) => {
	const validationRefs = useRef([])

	// Função para registrar um validador (evitando duplicados)
	const registerValidator = (ref) => {
		if (!validationRefs.current.includes(ref)) {
			validationRefs.current.push(ref)
		}
	}

	// Função para remover um validador
	const unregisterValidator = (ref) => {
		validationRefs.current = validationRefs.current.filter((v) => v !== ref)
	}

	// Função para disparar todas as validações
	const validateAll = () => {
		let acumulator = 0
		for(let validate of validationRefs.current){
			let valid = validate()
			if(valid) acumulator = acumulator+1
		}
		if(acumulator == validationRefs.current.length) return true
		/* return validationRefs.current.every((validate) => validate()) // Retorna true se todas as validações passarem */
	}

	return (
		<ValidationContext.Provider
			value={{ registerValidator, unregisterValidator, validateAll }}
		>
			{children}
		</ValidationContext.Provider>
	)
}

// Hook para usar o contexto de validação
export const useValidation = () => {
	return useContext(ValidationContext)
}
