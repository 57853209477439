import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
	
		descricaoDoProblema: '',
		dataDeAbertura: '',
		recurso: '',
		status: 'PENDENTE',
		statusId: '',
		userId: '',
		recursos : []
	},
	solicitacoes:[],

};

const solicitacaoDeServicoReducer = createSlice({
	name: 'solicitacaoDeServicoReducer',
	initialState,
	reducers: {
		setId(state, action) {
			state.form.id = action.payload;
		},
		
		setDescricaoDoProblema(state, action) {
			state.form.descricaoDoProblema = action.payload;
		},
		setRecursos(state, action) {
			state.form.recursos = action.payload;
		},
		setDataAbertura(state, action) {
			state.form.dataDeAbertura = action.payload;
		},
		setStatus(state, action) {
			state.form.status = action.payload;
		},
		setRecurso(state, action) {
			state.form.recurso = action.payload;
		},
		setSolicitante(state, action) {
			state.form.userId = action.payload;
		},
		setIsMaquinaParada(state, action) {
			state.maquinaParada = action.payload;
		},
		updateSingleSsRow(state, action) {
			let index = state.solicitacoes.findIndex(os => os.id == action.payload.id)
			state.solicitacoes[index] = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload;
		},
		clear(state, action) {
			state.form = initialState.form;
		},
		setSolicitacoes(state, action){
			state.solicitacoes =  action.payload
		},
		setApproved(state, action){
			let { index } = action.payload
			state.solicitacoes[index].aprovada = action.payload.aprovada
		}
	},
});

export const {
	setId,
	setModosDeFalha,
	setDescricaoDoProblema,
	setDataAbertura,
	setStatus,
	populateToEdit,
	addTarefas,
	clear : clearSolicitacaoDeServicoForm,
	setRecurso,
	setUnidadesDeMaterialTarefas,
	setSequenciaTarefa,
	setTarefas,
	setSolicitacoes,
	setSolicitante,
	setApproved,
	setRecursos,
	updateSingleSsRow,
	setIsMaquinaParada
} = solicitacaoDeServicoReducer.actions;
export default solicitacaoDeServicoReducer.reducer;
{
}
