import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Image, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { BiUserCircle } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PermissionContainer from '../Hocs/withPermission';
import { useWebProvider } from '../hooks/useWebProvider';
import scqlogo from '../img/logoscq.png';
import { enviromentnode, paths, version } from '../models/constantes';
import { logOut, setPageLocation } from '../Reducers/globalConfigReducer';
import { isAuthenticated } from '../Services/auth';
const MenuBar = () => {
  const global = useSelector(state => state.global)
  const socket = global.socketConnection
  const disconnectSocket = useWebProvider().connectSocket(global.userName, global.company)
  const dispatch = useDispatch()
  const otherNavigate = useNavigate()
  const allowedGestorDrop = ['OWNER', 'ADMIN', 'DEV', 'MANAGER']
  const logUserOut = () => {
    localStorage.clear()
    dispatch(logOut())
    dispatch({ type: "eraseStore" })
    otherNavigate('/Home')
  }
  

  const navClickHandler = (event) => {
    if ((event.target.className === "dropdown-item") || (event.target.className === "dropdown-item")) dispatch(setPageLocation(event.target.innerHTML))

  }

  const navigateToLogin = (eent) => {
    otherNavigate("Login")
  }

  if (isAuthenticated(global.token)) {
    return <>
      <div className='App tc f3'>
        <Navbar bg="light" expand="lg" onClick={(event) => navClickHandler(event)} >
          <NavItem style={{ marginRight: 20 }} /* onDoubleClick={() => navigate("/TestingAreaMenu")} */>{version}</NavItem>
          <Image height={50} width={80} src={scqlogo} rounded />

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav"  >
            <Nav className="mr-auto" >
              <Link className="nav-link" to="/Home">Home</Link>
              <Link className="nav-link" to={!global.isAuth ? "/Login" : "/Home"} onClick={() => global.isAuth && logUserOut()}>{!global.isAuth ? "Login" : "Logout"}</Link>
              {/* DEV MODE ONLY DO NOT PUSH ON MAIN PRODUCTION!!!!!!!!!!!!!!!!!!! */}
              <NavDropdown title="WorkPlan" id="basic-nav-dropdown">
                <Link className="dropdown-item" to={paths.workPlans} >WorkPlans</Link>
                <Link className="dropdown-item" to={paths.workFlows} >WorkFlows</Link>
                
              </NavDropdown>
              {/* DEV MODE ONLY DO NOT PUSH ON MAIN PRODUCTION!!!!!!!!!!!!!!!!!!! */}
              <NavDropdown title="Gestao de Parametros" id="basic-nav-dropdown">
                <Link className="dropdown-item" to={paths.cadernos}>Cadernos</Link>
              </NavDropdown>


              <NavDropdown title="Contador" id="basic-nav-dropdown">
                <Link className="dropdown-item" to={paths.contadorForm} >Novo contador</Link>
                <Link className="dropdown-item" to={paths.registroForm} >Registros</Link>
                <Link className="dropdown-item" to="/ApontarContador" >Apontamento</Link>
                <Link className="dropdown-item" to="/ConsultarContador" >Consultar</Link>
              </NavDropdown>



              <NavDropdown title="Manutenção" id="basic-nav-dropdown">
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.solicitacoesDeServico} ref={componenteRef} to={paths.solicitacoesDeServico}>Solicitação de Servico</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.ordensDeServico} ref={componenteRef} to={paths.ordensDeServico}>Ordem de Servico</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.arvoreEstruturalForm} ref={componenteRef} to={paths.arvoreEstruturalForm}>Arvore Estrutural</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.tipoDeOrdemForm} ref={componenteRef} to={paths.tipoDeOrdemForm} >Tipo De ordem</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.mantenedores} ref={componenteRef} to={paths.mantenedores} >Mantenedores</Link>
                    )
                  }}
                />
                  <PermissionContainer
                    menubar={true}
                    component={(componenteRef) => {
                      return (
                        <Link className="dropdown-item" id={paths.componenteForm} ref={componenteRef} to={paths.modoDeFalhaForm}>Modo de Falha</Link>
                      )
                    }}
                  />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.componenteForm} ref={componenteRef} to={paths.componenteForm}>Componente</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.componenteForm} ref={componenteRef} to={paths.acaoForm}>Ação</Link>
                    )
                  }}
                />
     

                <PermissionContainer
                menubar={true}
                component={(componenteRef) => {
                  return (
                   <Link className="dropdown-item" id={paths.ordensDeManutencao} ref={componenteRef} to={paths.ordensDeManutencao} >Ordem de Manutencao</Link> 
                  )
                }}
                />
                <PermissionContainer
                menubar={true}
                component={(componenteRef) => {
                  return (
                   <Link className="dropdown-item" id={paths.cronogramaManutencao} ref={componenteRef} to={paths.cronogramaManutencao} >Cronograma</Link> 
                  )
                }}
                />
                
              </NavDropdown>

              <NavDropdown title="Suprimentos" id="basic-nav-dropdown">
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.requisicoesDeMaterial} ref={componenteRef} to={paths.requisicoesDeMaterial}>Requisição de Material</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.estoques} ref={componenteRef} to={paths.estoques}>Estoques</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.materialForm} ref={componenteRef} to={paths.materialForm}>Material</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.kardex} ref={componenteRef} to={paths.kardex}>Kardex</Link>
                    )
                  }}
                />
              </NavDropdown>

           {/*    <NavDropdown title="Indicadores" id="basic-nav-dropdown">
              <Link className="dropdown-item" to="/IndicadorDeAnalise">Indicador de Análises</Link>
              <Link className="dropdown-item" to="/IndicadorDeGastos">Indicador de Gastos</Link>
            </NavDropdown> */}
              {<NavDropdown title="Processo" id="basic-nav-dropdown">
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.processoForm} ref={componenteRef} to={paths.processoForm}>Processo</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.fluxosDeProcesso} ref={componenteRef} to={paths.fluxoDeProcessoForm}>Fluxo de Processo</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.operacaoForm} ref={componenteRef} to={paths.operacaoForm}>Operação</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.planosDeControle} ref={componenteRef} to={paths.planosDeControle}>Plano de Controle</Link>
                    )
                  }}
                />
                <PermissionContainer
                  menubar={true}
                  component={(componenteRef) => {
                    return (
                      <Link className="dropdown-item" id={paths.parametroForm} ref={componenteRef} to={paths.parametroForm}>Parametro</Link>
                    )
                  }}
                />
              </NavDropdown>}
              {allowedGestorDrop.map(allowed => {
                if (global.role?.nome === allowed) {
                  return (
                    <NavDropdown title="Gestão" id="basic-nav-dropdown">
                      <PermissionContainer
                        menubar={true}
                        component={(componenteRef) => {
                          return (
                            <Link className="dropdown-item" id={paths.setorForm} ref={componenteRef} to={paths.setorForm}>Setor</Link>
                          )
                        }}
                      />
                      <PermissionContainer
                        menubar={true}
                        component={(componenteRef) => {
                          return (
                            <Link className="dropdown-item" id={paths.times} ref={componenteRef} to={paths.times}>Times</Link>
                          )
                        }}
                      />
                      <PermissionContainer
                        menubar={true}
                        component={(componenteRef) => {
                          return (
                            <Link className="dropdown-item" id={paths.timeForm} ref={componenteRef} to={paths.timeForm}>Time Form</Link>
                          )
                        }}
                      />
                    </NavDropdown>
                  )
                }
              })}
            </Nav>



          </Navbar.Collapse>
          <NavItem style={{ fontSize: "1.4rem" }}><strong>{global.company}</strong></NavItem>
          
            <NavItem style={{color: 'red'}} hidden={!enviromentnode}> <h4>DEV-MODE ON</h4></NavItem>
          <NavItem style={{ color: socket ? "green" : "red", marginRight: 20, marginLeft: "9%" }}>{socket ? "Connected" : "Offline"}</NavItem>
          {!isMobile && <NavItem style={{ marginRight: 20 }}><BiUserCircle size={24} /> {global.firstName} {global.lastName}</NavItem>}
        </Navbar>

      </div>
    </>

  } else {
    return (
      <div className='App tc f3'>
        <Navbar bg="light" expand="lg" onClick={(event) => navClickHandler(event)} >
          {/* <NavItem style={{ marginRight: 20 }} onDoubleClick={() => navigate("/TestingAreaMenu")}>{version}</NavItem> */}
          <Image height={50} width={80} src={scqlogo} rounded />

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav"  >
            <Nav className="mr-auto" >
              <Link className="nav-link" to="/Home">Home</Link>
              <Link className="nav-link" to={!global.isAuth ? "/Login" : "/Home"} onClick={() => global.isAuth ? logUserOut() : navigateToLogin()}>{!global.isAuth ? "Login" : "Logout"}</Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
  
  
}





export default MenuBar;