import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	form: {
		tipo: "Dia",
		frequencia: 1,
		diasSemana: [],
		diasMes: [],
		dataInicio: "",
		dataFim: "",
		maxTimesToTrigger: '0'
	},
}

const recorrenciaReducer = createSlice({
	name: "recorrenciaReducer",
	initialState,
	reducers: {
		setTipoRecorrencia(state, action) {
			state.form.tipo = action.payload
		},
		setFrequenciaRecorrencia(state, action) {
			state.form.frequencia = action.payload
		},
		setDiasSemana(state, action) {
			state.form.diasSemana = action.payload
		},
		setDiasMes(state, action) {
			state.form.diasMes = action.payload
		},
		setDataInicio(state, action) {
			state.form.dataInicio = action.payload
		},
		setDataFim(state, action) {
			state.form.dataFim = action.payload
		},
		setMaximoOcorrencias(state, action) {
			state.form.maxTriggedTimes = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		clear(state) {
			state.form = initialState.form
		},
	},
})

export const {
	setDataFim,
	setDiasMes,
	setDataInicio,
	setDiasSemana,
	setTipoRecorrencia,
	setMaximoOcorrencias,
	setFrequenciaRecorrencia,
	clear: clearRecorrenciaForm,
	populateToEdit: populateToEditRecorrenciaForm,
} = recorrenciaReducer.actions
export default recorrenciaReducer.reducer
