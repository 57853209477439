import { FloatingLabel, Form, InputGroup } from 'react-bootstrap';

const FormField = ({
	val,
	onValueUpdate,
	onBlur,
	label,
	ty,
	actionClick,
	reference,
	others,
	styleObj,
	locked,
	hide,
	onMousEnt,
	onMouseLv,
	initiateValue,
	onEnterPress,
	defaultValue,
	className,
	isInvalid
}) => {
	const onKeyDownHandler = (event) => {
		if (event.code == 'Enter') {
			onEnterPress(event.target.value);
		}
	};
	return (
		<>
			<Form.Group
				hidden={hide}
				onMouseLeave={onMouseLv}
				onKeyDown={onKeyDownHandler}
				style={{ marginTop: 4, marginBottom: 4 }}>
				<InputGroup>
					<FloatingLabel style={{zIndex: 0}} label={label} controlId='floatingInput'>
						<Form.Control
						 autoComplete='off'
						 isInvalid={isInvalid}
							className={className}
							{...others}
							required={others?.required}
							onBlur={(event) => onBlur && onBlur(event.target.value)}
							disabled={locked}
							style={styleObj}
							ref={reference}
							min={others?.min}
							placeholder={others?.placeholder || label}
							max={others?.max}
							type={ty || 'text'}
							value={val || defaultValue}
							onChange={(event) =>
								onValueUpdate && onValueUpdate(event.target.value)
							}></Form.Control>
					</FloatingLabel>
					{actionClick && actionClick()}
				</InputGroup>
			</Form.Group>
		</>
	);
};

export default FormField;
