import { combineReducers } from "redux"
import branchLevelReducer from "./branchLevelReducer"
import classeDeRecursoReducer from "./classeDeRecursoReducer"
import alarmeReducer from "./commons-reducers/alarmeReducer"
import cachedReducer from "./commons-reducers/cachedReducer"
import frequenciaReducer from "./commons-reducers/frequenciaReducer"
import justificativaReducer from "./commons-reducers/justificativaReducer"
import registroReducer from "./commons-reducers/registroReducer"
import setorReducer from "./commons-reducers/setorReducer"
import statusReducer from "./commons-reducers/statusReducer"
import turnoReducer from "./commons-reducers/turnoReducer"
import unidadeReducer from "./commons-reducers/unidadeReducer"
import componenteReducer from "./componenteReducer"
import composicaoReducer from "./composicaoReducer"
import consumoReducer from "./consumoReducer"
import dynamicFormsReducer from "./dyanamicForms"
import FormularioReducer from "./formularioReducer"
import genericPickerReducer from "./genericPickerReducer"
import grupoReducer from "./gestao-reducers/grupoReducer"
import teamMemberReducer from "./gestao-reducers/teamMemberReducer"
import timeReducer from "./gestao-reducers/timeReducer"
import workGroupReducer from "./gestao-reducers/workGroupReducer"
import globalConfig from "./globalConfigReducer"
import cronogramaManutencaoReducer from "./manutencao-reducers/CronogramaManutencaoReducer"
import acaoReducer from "./manutencao-reducers/acaoReducer"
import arvoreEstruturalReducer from "./manutencao-reducers/arvoreEstruturalReducer"
import classeComponenteReducer from "./manutencao-reducers/classeDeComponenteReducer"
import dimensaoReducer from "./manutencao-reducers/dimensaoReducer"
import mantenedorReducer from "./manutencao-reducers/mantenedorReducer"
import manutencaoReducer from "./manutencao-reducers/manutencaoReducer"
import materialReducer from "./manutencao-reducers/materialReducer"
import modoDeFalhaReducer from "./manutencao-reducers/modoDeFalhaReducer"
import ordemDeManutencaoReducer from "./manutencao-reducers/ordemDeManutencaoReducer"
import ordemDeServicoReducer from "./manutencao-reducers/ordemDeServicoReducer"
import planoDeManutencaoReducer from "./manutencao-reducers/planoDeManutencaoReducer"
import recursoReducer from "./manutencao-reducers/recursoReducer"
import registroDeTarefaReducer from "./manutencao-reducers/registroDeTarefaReducer"
import solicitacaoDeServicoReducer from "./manutencao-reducers/solicitacaoDeServicoReducer"
import tarefaReducer from "./manutencao-reducers/tarefaReducer"
import tipoDeOrdemReducer from "./manutencao-reducers/tipoDeOrdemReducer"
import trocaReducer from "./manutencao-reducers/trocaReducer"
import pickersReducer from "./pickersReducer"
import FolhaDeVerificacaoReducer from "./processo-reducers/FolhaDeVerificacaoReducer"
import cadernoDeVerificacaoReducer from "./processo-reducers/cadernoDeVerificacaoReducer"
import calculoDeCorrecaoReducer from "./processo-reducers/calculoDeCorrecaoReducer"
import controleReducer from "./processo-reducers/controleReducer"
import especificacaoReducer from "./processo-reducers/especificacaoReducer"
import fluxoDeProcessoReducer from "./processo-reducers/fluxoDeProcessoReducer"
import limiteDeControleReducer from "./processo-reducers/limiteDeControleReducer"
import linhaDePlanoDeControleReducer from "./processo-reducers/linhaDePlanoDeControleReducer"
import operacaoReducer from "./processo-reducers/operacaoReducer"
import parametroReducer from "./processo-reducers/parametroReducer"
import planoDeControleReducer from "./processo-reducers/planoDeControleReducer"
import processoReducer from "./processo-reducers/processoReducer"
import regraDeCorrecaoReducer from "./processo-reducers/regraDeCorrecaoReducer"
import respostaFormulario from "./respostaFormularioReducer"
import estoqueReducer from "./suprimentos-reducers/estoqueReducer"
import kardexReducer from "./suprimentos-reducers/kardexReducer"
import movimentacaoReducer from "./suprimentos-reducers/movimentacaoReducer"
import requisicaoMaterialReducer from "./suprimentos-reducers/requisicaoMaterialReducer"
import unidadeMaterialReducer from "./suprimentosReducer/unidadeMaterialReducer"
import trocaComponenteReducer from "./trocaComponenteReducer"
import webProviderReducer from "./webProviderReducer"
import agregadorReducer from "./workplan-reducers/agregadorReducer"
import recorrenciaReducer from "./workplan-reducers/recorrenciaReducer"
import regraDeAgregacaoReducer from "./workplan-reducers/regraDeAgregacaoReducer"
import workReducer from "./workplan-reducers/workReducer"

const appReducer = combineReducers({
	acao: acaoReducer,
	agregador: agregadorReducer,
	alarme: alarmeReducer,
	arvoreEstrutural: arvoreEstruturalReducer,
	branchLevel: branchLevelReducer,
	cached: cachedReducer,
	cadernoDeVerificacao: cadernoDeVerificacaoReducer,
	calculoDeCorrecao: calculoDeCorrecaoReducer,
	classeDeComponente: classeComponenteReducer,
	classeDeRecurso: classeDeRecursoReducer,
	componente: componenteReducer,
	composicao: composicaoReducer,
	consumo: consumoReducer,
	controle: controleReducer,
	cronogramaManutencao: cronogramaManutencaoReducer,
	dimensao: dimensaoReducer,
	dynamicForms: dynamicFormsReducer,
	especificacao: especificacaoReducer,
	estoque: estoqueReducer,
	fluxoDeProcesso: fluxoDeProcessoReducer,
	folhaDeVerificacao: FolhaDeVerificacaoReducer,
	formulario: FormularioReducer,
	frequencia: frequenciaReducer,
	global: globalConfig,
	grupo: grupoReducer,
	justificativa: justificativaReducer,
	kardex: kardexReducer,
	limiteDeControle: limiteDeControleReducer,
	linhaDePlanoDeControle: linhaDePlanoDeControleReducer,
	manutencao: manutencaoReducer,
	mantenedor: mantenedorReducer,
	material: materialReducer,
	modoDeFalha: modoDeFalhaReducer,
	movimentacao: movimentacaoReducer,
	operacao: operacaoReducer,
	ordemDeManutencao: ordemDeManutencaoReducer,
	ordemDeServico: ordemDeServicoReducer,
	parametro: parametroReducer,
	pickers: pickersReducer,
	picker: genericPickerReducer,
	planoDeControle: planoDeControleReducer,
	planoDeManutencao: planoDeManutencaoReducer,
	processo: processoReducer,
	recorrencia: recorrenciaReducer,
	recurso: recursoReducer,
	registro: registroReducer,
	registroDeTarefa: registroDeTarefaReducer,
	regraDeAgregacao: regraDeAgregacaoReducer,
	regraDeCorrecao: regraDeCorrecaoReducer,
	respostaDeFormulario: respostaFormulario,
	requisicaoMaterial: requisicaoMaterialReducer,
	setor: setorReducer,
	solicitacaoDeServico: solicitacaoDeServicoReducer,
	status: statusReducer,
	tarefa: tarefaReducer,
	team: timeReducer,
	teamMember: teamMemberReducer,
	tipoDeOrdem: tipoDeOrdemReducer,
	troca: trocaReducer,
	trocaComponente: trocaComponenteReducer,
	turno: turnoReducer,
	unidade: unidadeReducer,
	unidadeMaterial: unidadeMaterialReducer,
	webProvider: webProviderReducer,
	work: workReducer,
	workGroup: workGroupReducer,
})

const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === "eraseStore") {
		state.justificativa = undefined
		state.contador = undefined
		state.frequencia = undefined
		state.requisicaoMaterial = undefined
		state.team = undefined
		state.registroDeTarefa = undefined
		state.setor = undefined
		state.recorrencia = undefined
		state.kardex = undefined
		state.registro = undefined
		state.agregador = undefined
		state.manutencao = undefined
		state.ordemDeManutencao = undefined
		state.mantenedor = undefined
		state.planoDeManutencao = undefined
		state.turno = undefined
		state.global = undefined
		state.teamMember = undefined
		state.formsReducer = undefined
		state.unidadeMaterial = undefined
		state.recurso = undefined
		state.grupo = undefined
		state.acao = undefined
		state.classeDeRecurso = undefined
		state.consumo = undefined
		state.solicitacaoDeServico = undefined
		state.ordemDeServico = undefined
		state.pickers = undefined
		state.limiteDeControle = undefined
		state.especificacao = undefined
		state.parametro = undefined
		state.alarme = undefined
		state.unidade = undefined
		state.tipoDeOrdem = undefined
		state.componente = undefined
		state.classeDeComponente = undefined
		state.trocaComponente = undefined
		state.ordemDeManutencao = undefined
		state.manutencao = undefined
		state.estoque = undefined
		state.formulario = undefined
		state.dynamicForms = undefined
		state.respostaDeFormulario = undefined
		state.folhaDeVerificacao = undefined
		state.cronogramaManutencao = undefined
		state.status = undefined
		state.movimentacao = undefined
		state.arvoreEstrutural = undefined
		state.branchLevel = undefined
		state.planoDeControle = undefined
		state.fluxoDeProcesso = undefined
		state.operacao = undefined
		state.processo = undefined
		state.linhaDePlanoDeControle = undefined
		state.modoDeFalha = undefined
		state.material = undefined
		state.tarefa = undefined
		state.cadernoDeVerificacao = undefined
		state.dimensao = undefined
		state.controle = undefined
		state.webProvider = undefined
		state.cached = undefined
		state.picker = undefined
		state.composicao = undefined
		state.regraDeCorrecao = undefined
		state.calculoDecorrecao = undefined
		state.correcao = undefined
		state.regraDeAgregacao = undefined
		state.work = undefined
	}

	return appReducer(state, action)
}

export default rootReducer
