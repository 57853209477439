import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	form: {
		workPlanId: null,
		config: null,
	},
	works: []
}

const workReducer = createSlice({
	name: "workReducer",
	initialState,
	reducers: {
		setWorkPlanId(state, action) {
			state.form.workPlanId = action.payload
		},
		setConfigWork(state, action) {
			let {config, workListMode} = action.payload
			state.form.config = config
			if(workListMode) state.works = [...state.works, state.form]
		},
		setWorks(state, action) {
			state.works = action.payload
		},
		clear(state) {
			state.form = initialState.form
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
	},
})

export const {
	setWorkPlanId,
	setConfigWork,
	clear: clearWork,
	populateToEdit: populateToEditWork,
	setWorks
} = workReducer.actions

export default workReducer.reducer
